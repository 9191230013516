import React, {useEffect, useState} from 'react'
import ReactConfetti from 'react-confetti'

const Land = () => {
  const [windowDimension, setDimension] = useState({width: window.innerWidth, height: window.innerHeight})

  const detectSize = () => {
    setDimension({width: window.innerWidth, height: window.innerHeight});
  }
  useEffect(() => {
    window.addEventListener('resize', detectSize)
    return () => {
      window.removeEventListener('resize', detectSize)
    }
  }, [])

  return (
    <div className="success-donation">
      <ReactConfetti width={windowDimension.width} height={windowDimension.height} tweenDuration={1000} />
      <div className="success">
        </div>
    
    </div>
  )
}

export default Land
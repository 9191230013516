import React from "react";
import "./Testimonial.css";

const Testimonial = () => {
  return (
    <>
      <div className="testimonial">
        <div className="header">
          <p>To,</p>
          <p>Riya 😉</p>
        </div>
        <div className="body">
          <p>
            This is the first and the only letter 📃 am writing for you, Riya
            😊. This might be a little long one but read it till the end, cause
            I have penned 🖋️ down my whole feelings and moments I have spend
            with you. Surely, you are gonna enjoy reading it (pre-requisite is
            just sit quitely 🤫 in ur room and read…)
          </p>
          <p>
            It was 2nd year, this was the first time I saw you (before that our
            sec were diff). Only god knows why I used to blush looking at you 😅
            and also used to stare 👀 at you during the electronics lab.
          </p>
          <p>
            Fast forward to the 3rd year, we again started talking just before
            Eutopia started. (Woh bolte h nah ki, agar kismat me likha h toh,
            mulakat zarur hoga and see we are here today). You won’t believe
            that ami tokei hackathon a nite cheyechilm ebare Riya but team
            member der samne seta bolini, ami oder e decide korte bolechilm to
            take a girl karon ami jantam at the end amakei khujte bolbe ora.
            Sudhu akta jinis e amay atkachilo seta hocche amra talking terms a
            chilm na ar hut kore toke 36 hrs ⌛ offline hackathon er jonno bolle
            tui ki bhabbi setar jonno. But see, again it was you who I asked
            for.{" "}
          </p>
          <p>
            Then Eutopia holo. To be honest, ami first, je toke jiggesh
            korechilm tui aschis kina because back of my mind somewhere I also
            wanted to meet you and spend time 👫 with you that day. And it went
            to be the best day with the best person ❤️. I never confessed this
            but bari ferar somoe I realized that amr abr tor proti feelings 👉👈
            chole asechilo .{" "}
          </p>

          <p>
            Then hackathon holo and that event I would say is the turning ↪️
            point of my love life. I fell for you permanently 🥰. Every small
            things I did, be it - sitting with you whole night coding (you were
            with me and didn’t went for sleep), playing truth and dare, dancing
            with you on songs, keeping my head on yours -each and every small
            things I loved spending with you Riya 😊.
          </p>

          <p>
            Then came 8th Feb, the first and the last girl I proposed 💍 in my
            entire college life. The proposal wasn’t that good but okay. And
            sedin I realized, je toke kotota kosto die felechi ami ojantei. Ar
            orom bhabe amio kokhono kadini karor samne cause I never show tears
            infront of others. And that day I made a commitment 😌 to myself
            that whatever the situation be, I would never gonna hurt you and
            leave you alone again.
          </p>

          <p>
            Then came 12th Feb, that was the first day I went out with you at
            Smart Plaza. Toke khola chul 👩 e khub sundor lage, chokh soratei pari
            nah 👉👈. Ar sedin prothom bar I kissed and hugged you 👩‍❤️‍👨. Jni na kno but
            toke joriye thakte iche korchilo, bari jetei mon chilo nah sedin.
            Khub short time er jonne chilm thik e, kintu ota amr jonne onk chilo
            Riya 😌.
          </p>

          <p>
            Then comes our First Date, Princep Ghat. And I was really waiting
            for this day for a long time. Date a ami niye gachilm toke but at
            the end tui rasta khuje khuje ghorachili amay 😂. Khub sundor lagchilo
            toke sedin 🙈 ar cuddle o korte iche korchilo khub. I wished puro din
            ta thaktis amr sathe, but tor poranor chilo 🥺. Tor hat dhore ghora,
            bench a bosa – sob kichu I was really enjoying, spending time with
            you. Amr kache tor choto boro sob efforts e onk mone hoe re and I want
            to stay with you with all these things.
          </p>

          <p>
            College a ele to first a amr chokh tokei khoje Riya, tui asechis
            kina, kothay bosechis. Ar beronor ageo chesta kori jate atleast toke
            bye bole jete pari. Ei sob choto boro jinis niyei ami tor sathe
            thakte chai re. Jni na tor kirom feel hoe, but tor sathe kotha na
            hole ami bawal hoe jai, thakte pari nah 😔. Amr o khub iche kore tor
            sathe sob kichu share korte seta korio o. Meet a jokhon e asi, ami
            puro din er story toke age sonai, ar bhalo kono incident hole to
            seta sonaiee.{" "}
          </p>

          <p>
            Tarpor choto boro onk somossai eseche 💔 but amr love tor proti
            kokhonoi komeni Riya. Ektu rege jetam thik e, but seta besikkhon
            pusiye rakhte partam nah. Ar eta shikar korte amr kokhonoi osubidha
            hoe nah je you are the only girl in my entire college life jar kache
            ese ami emotionally attached hoe pori and I fell in love 😌. Aar amr
            o 3te clear statement sune rakh 👇:
          </p>
          <p>
            ☑️ Jai hoye jak na kno, amr tor proti bhalobasa kombe na (likhe
            rakh, eta amr commitment)
          </p>
          <p>
            {" "}
            ☑️ And ami toke unconditionally bhalobasi, ar baki tui jai paris
            (tor achievements and all) segulo toh add-on
          </p>
          <p>☑️ Ar ami tor sob somoe pase achi ...</p>
          <p>
            Tor onk well wishers ache I know, but I am also one of them. Ami
            chai tui khub happy thak, ar ami sob somoe toke support korbo 😊 and
            pase thakbo ❤️.
          </p>
        </div>
        <div className="footer">
          <div className="footer-desc">
            <p>From,</p>
            <p>Sayan 😌</p>
          </div>
        </div>
      </div>
      <div className="nick-name">
        <p>Ar tor official nickname from my side , </p>
        <h1>Mistu 😛</h1>
      </div>
    </>
  );
};

export default Testimonial;

import "./App.css";
import Land from "./components/Land";
import Testimonial from "./components/testimonial/Testimonial";
import ImageGallery from "./components/image-gallery/ImageGallery";
// import { hotjar } from "react-hotjar";
import Hotjar from "@hotjar/browser";
import { useEffect } from "react";

function App() {
  const HOTJAR_SITEID = process.env.REACT_APP_HOTJAR_SITEID;
  const HOTJAR_VERSION = process.env.REACT_APP_HOTJAR_VERSION;

  // HotJar Integration :
  // useEffect(() => {
  //   hotjar.initialize(HOTJAR_SITEID, HOTJAR_VERSION);
  // }, []);

  const siteId = 3925784;
  const hotjarVersion = 6;

  Hotjar.init(siteId, hotjarVersion);

  return (
    <div className="app">
      <div className="heading">
        <div className="desc">
          <p>For one & only,</p>
          <p>Riya 😉</p>
        </div>
      </div>
      <Land />
      <div className="main-avatar">
        <div class="gallery">
          <ImageGallery />
        </div>
      </div>
      <div className="gap"></div>
      <Testimonial />
    </div>
  );
}

export default App;

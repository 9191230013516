import React from 'react'
import './ImageGallery.css'
import Image1 from "../../assets/image/1.jpg"
import Image2 from "../../assets/image/2.jpg"
import Image3 from "../../assets/image/3.jpg"
import Image4 from "../../assets/image/4.jpg"
import Image5 from "../../assets/image/5.jpg"
import Image6 from "../../assets/image/6.jpg"
import Image7 from "../../assets/image/7.jpg"
import Image8 from "../../assets/image/11.jpg"
import Image9 from "../../assets/image/12.jpg"
import Image10 from "../../assets/image/10.jpg"

const ImageGallery = () => {
  return (
    <div class="box">
        <span style={{"--i" : "1"}}><img src={Image1} alt="" /></span>
        <span style={{"--i" : "2"}}><img src={Image2} alt="" /></span>
        <span style={{"--i" : "3"}}><img src={Image3} alt="" /></span>
        <span style={{"--i" : "4"}}><img src={Image4} alt="" /></span>
        <span style={{"--i" : "5"}}><img src={Image5} alt="" /></span>
        <span style={{"--i" : "6"}}><img src={Image6} alt="" /></span>
        <span style={{"--i" : "7"}}><img src={Image7} alt="" /></span>
        <span style={{"--i" : "8"}}><img src={Image8} alt="" /></span>
        <span style={{"--i" : "9"}}><img src={Image9} alt="" /></span>
        <span style={{"--i" : "10"}}><img src={Image10} alt="" /></span>
    </div>
  )
}

export default ImageGallery
